import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonGroup,
  FormController,
  FormLabel, Radio,
  RadioGroup,
  SelectionControlLabel,
  DrawerBody,
  DrawerFooter,
  Alert
} from '@one-thd/sui-atomic-components';
import {
  useLazyDataModel, useDataModel, extend,
  arrayOf, params, string, number, customType,
} from '@thd-nucleus/data-sources';
import { ProductInfo } from './ProductInfo';
import { AddToListSuccess } from './AddToListSuccess';
import { OPERATION_ADD_ITEMS, TRACK } from '../constants';

export const AddToListForm = ({
  itemId,
  quantity,
  shouldCreateList,
  listDetails,
  onClose,
  products,
  isSharedList,
  setFavoriteCount,
  favoriteCount,
  storeId
}) => {
  const [selectedList, setSelectedList] = useState({ listId: null, listName: null });

  const { data } = useDataModel('product', {
    variables: {
      itemId,
      storeId
    },
    skip: !itemId
  });

  const { product } = data || {};
  const singleProduct = [
    { itemId, quantity }
  ];

  const multipleProduct = products.map((prod) => ({
    itemId: prod.itemId,
    quantity: prod.quantity
  }));

  // Query to add item(s) to a list
  const [updateList, updateListResponse] = useLazyDataModel('updateList', {
    variables: {
      id: selectedList?.id,
      products: itemId ? singleProduct : multipleProduct.reverse(),
      operationName: OPERATION_ADD_ITEMS
    },
    fetchPolicy: 'no-cache',
    context: { withAuth: true }
  });

  useEffect(() => {
    if (updateListResponse?.error) {
      const gqlErrors = updateListResponse?.error?.graphQLErrors;
      gqlErrors.forEach((err, index) => {
        if (err?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
          setSelectedList({
            ...selectedList,
            error: 'Apologies, a temporary technical issue occured. Please try again soon.'
          });
        } else {
          setSelectedList({
            ...selectedList,
            error: err?.msg
          });
        }
      });
    }
  }, [updateListResponse.error]);

  useEffect(() => {
    if (updateListResponse?.data?.updateList?.id && itemId) {
      LIFE_CYCLE_EVENT_BUS.trigger(TRACK.ADD_ITEM, {
        quantity, price: product?.pricing?.value || 0, sku: itemId
      });
    }
    if (updateListResponse?.data?.updateList?.id && products && !itemId) {
      const addToListLocation = isSharedList ? 'shared list' : 'existing list';
      const listItem = products.map((prod) => ({
        listLocation: addToListLocation,
        quantity: prod.quantity || 'n/a',
        price: { basePrice: prod.price || 'n/a' },
        productInfo: { sku: prod.itemId || 'n/a' }
      }));
      LIFE_CYCLE_EVENT_BUS.trigger(TRACK.ADD_PRODUCTS, {
        listItem
      });
    }
  }, [updateListResponse?.data?.updateList]);

  const selectList = (list) => {
    setSelectedList({
      id: list?.listId,
      name: list?.listName
    });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    await updateList({
      variables: {
        id: selectedList?.id
      }
    });
  };

  useEffect(() => {
    if (updateListResponse?.data?.updateList?.id) {
      setFavoriteCount(favoriteCount + 1);
    }
  }, [updateListResponse?.data?.updateList?.id]);

  if (updateListResponse?.data?.updateList?.id) {
    return (
      <AddToListSuccess
        listName={selectedList?.name}
        listId={updateListResponse?.data?.updateList?.id}
        onClose={onClose}
      />
    );
  }

  return (
    <>
      <DrawerBody>
        {
          selectedList?.error && (
            <Alert status="error">{selectedList?.error}</Alert>
          )
        }
        <ProductInfo itemId={itemId} />
        <Button
          className="sui-mb-4 sui-mt-12 sui-mx-6 sui-text-base sui-flex"
          variant="text"
          onClick={() => { shouldCreateList(true); }}
        >
          <u>Create New List</u>
        </Button>
        <div className="sui-pb-4 sui-px-6 sui-text-base">-or-</div>
        <FormController className="sui-px-6">
          <FormLabel id="list-names" className="sui-cursor-default sui-text-base">
            Add to An Existing List:
          </FormLabel>
          <div className="sui-pt-10">
            <RadioGroup
              aria-labelledby="list-names"
              name="list-names"
            >
              {(listDetails || []).map((list) => {
                return (
                  <SelectionControlLabel
                    className="sui-break-all"
                    key={list.listId}
                    value={list.listId}
                    label={list.listName}
                    onChange={(evt) => { selectList(list); }}
                  >
                    <Radio />
                  </SelectionControlLabel>
                );
              })}
            </RadioGroup>
          </div>
        </FormController>
      </DrawerBody>
      <DrawerFooter>
        <ButtonGroup orientation="vertical">
          <Button
            fullWidth
            variant="primary"
            onClick={handleSubmit}
            type="submit"
            disabled={!selectedList?.id}
            data-testid="save-list"
          >
            Add to List
          </Button>
          <Button
            fullWidth
            variant="secondary"
            onClick={onClose}
            data-testid="cancel-add-to-list"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

AddToListForm.propTypes = {
  itemId: PropTypes.string,
  quantity: PropTypes.number,
  shouldCreateList: PropTypes.func.isRequired,
  isSharedList: PropTypes.bool.isRequired,
  listDetails: PropTypes.arrayOf(PropTypes.shape({
    listId: PropTypes.string,
    listName: PropTypes.string
  })),
  onClose: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number
  })),
  setFavoriteCount: PropTypes.func.isRequired,
  favoriteCount: PropTypes.number,
  storeId: PropTypes.string.isRequired
};

AddToListForm.defaultProps = {
  itemId: null,
  quantity: 1,
  listDetails: [],
  products: [],
  favoriteCount: null
};

AddToListForm.dataModel = extend(
  {
    updateList: params({
      id: string().isRequired(),
      operationName: customType('UpdateOperation').enum(['Update', 'RemoveItems', 'AddItems']),
      products: arrayOf(customType('ProductInput').shape({
        itemId: string(),
        quantity: number(),
      }).isRequired()
      )
    }).mutation().shape({
      id: string()
    })
  },
  {
    product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
      itemId: string(),
      pricing: params({ storeId: string() }).shape({
        value: number({ float: true })
      })
    })
  },
  ProductInfo
);
